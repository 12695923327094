import * as Moment from 'moment'
import { extendMoment } from 'moment-range'
const moment = extendMoment(Moment)

export const settings = {
  orderStart: '2025-03-21 00:00',
  orderEnd:   '2025-04-07 23:59'
}

export function checkOrderDate (): boolean {
  const range = moment.range(
    moment(settings.orderStart, 'YYYY-MM-DD HH:mm'),
    moment(settings.orderEnd, 'YYYY-MM-DD HH:mm')
  )
  return range.contains(moment())
}

export function checkDateRange(startDate: string, endDate: string) {
  const range = moment.range(
    moment(startDate, 'YYYY-MM-DD HH:mm'),
    moment(endDate, 'YYYY-MM-DD HH:mm')
  )
  return range.contains(moment())
}