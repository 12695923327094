<template>
  <b-modal
    ref="modal"
    title="Laufende Bestellaktion"
    header-bg-variant="warning"
    body-bg-variant="light"
    footer-bg-variant="light"
    header-border-variant="light"
    footer-border-variant="light"
    centered
  >
    <div class="text-center" style="font-size: 17px;">
      <p>Der SAC Ebenhausen räuchert für Sie Forellen und Makrelen!</p>
      <p class="font-weight-bold">Schon vorbestellt?</p>
    </div>
    <template #modal-footer>
      <div class="d-flex justify-content-center w-100">
        <b-button :to="{ name: 'Order' }">
          Zur Vorbestellung
          <b-icon-arrow-right-square />
        </b-button>
      </div>
    </template>
  </b-modal>
</template>

<script>
export default {
  methods: {
    open () {
      setTimeout(() => {
        this.$refs.modal.show()
      }, 500)
    }
  }
}
</script>

<style>
</style>
